
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import {
  IArea, IAreaBox, IAreaBoxStatus, IAreaValorPeriodo,
} from '@/models/Entidades/IArea';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import Icone from '@/core/components/UI/Icone.vue';
import Card from '@/core/components/UI/Card.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import SelecionarDataInicialFinal from '@/core/components/UI/SelecionarDataInicialFinal.vue';
import ServicoArea from '@/servicos/ServicoArea';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import ComboTipoCobranca from '@/components/Areas/ComboTipoCobrancaBox.vue';
import ComboAreaTipoControle from '@/components/Areas/ComboAreaTipoControle.vue';
import ComboBoxStatus from '@/components/Areas/ComboBoxStatus.vue';
import { EBoxStatus } from '@/models/Enumeradores/EBoxStatus';
import { ETipoCobrancaBox } from '@/models/Enumeradores/ETipoCobrancaBox';
import SelecionarDiaMes from '@/core/components/UI/SelecionarDiaMes.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import AreaBoxImagens from '@/components/Areas/AreaBoxImagens.vue';

export default defineComponent({
  name: 'AreaCadastro',
  components: {
    ComunicacaoApi,
    Icone,
    Card,
    EditorHtml,
    ComboTipoCobranca,
    ComboAreaTipoControle,
    ComboBoxStatus,
    CampoNumerico,
    SelecionarDataInicialFinal,
    SelecionarDiaMes,
    AreaBoxImagens,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, obterCodigoRota,
    } = useAppBase();
    const servicoArea = new ServicoArea();

    const state = reactive({
      area: {} as IArea,
      boxLarguraEmMassa: 0,
      boxAlturaEmMassa: 0,
      isDragging: false,
      indexBoxDrag: -1,
      indexBoxEdicao: -1,
      startX: 0,
      startY: 0,
      chaveValores: '',
      boxEditarInformacoes: {} as IAreaBox,
      apresentarModalEditarInformacaoBox: false,
    });

    async function obterArea(codigo:number) {
      appBase.carregando = true;
      state.area = await servicoArea.obter(codigo);
      appBase.carregando = false;
    }

    function preencheAreaBox(codigoStatusBox:number, index:number) {
      const box:IAreaBox = {} as IAreaBox;
      box.codigo = 0;
      box.codigoArea = state.area.codigo;
      box.identificacao = index.toString();
      box.codigoBoxStatus = codigoStatusBox;
      box.valor = 0;
      box.largura = 30;
      box.altura = 40;
      box.posicaoY = 0;
      box.posicaoX = 0;
      box.estilo = '';
      box.imagens = [];
      state.area.boxes.push(box);
    }

    function atualizarQuantidadeBox() {
      if (state.area.codigo > 0) {
        if ((state.area.quantidadeBox - 1) === state.area.boxes.length) { return; }

        let codigoStatusBox = 0;
        const statusBoxDisponivel = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Disponivel);
        if (statusBoxDisponivel !== undefined) {
          codigoStatusBox = statusBoxDisponivel.codigo;
        }
        if (state.area.quantidadeBox < (state.area.boxes.length + 1)) {
        // eslint-disable-next-line for-direction
          for (let index = state.area.boxes.length; index > (state.area.quantidadeBox - 1); index -= 1) {
            state.area.boxes.splice(index, 1);
          }
        } else if (state.area.boxes.length === 0) {
          for (let index = 1; index < (state.area.quantidadeBox + 1); index += 1) {
            preencheAreaBox(codigoStatusBox, index);
          }
        } else if (state.area.boxes.length > 0) {
          for (let index = (state.area.boxes.length + 1); index < (state.area.quantidadeBox + 1); index += 1) {
            preencheAreaBox(codigoStatusBox, index);
          }
        }
      }
    }

    function preencherBoxConformeQuantidade() {
      let codigoStatusBox = 0;
      const statusBoxDisponivel = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Disponivel);
      if (statusBoxDisponivel !== undefined) {
        codigoStatusBox = statusBoxDisponivel.codigo;
      }

      for (let index = 1; index < (state.area.quantidadeBox + 1); index += 1) {
        preencheAreaBox(codigoStatusBox, index);
      }
    }

    function atualizarLarguraTodosBoxes() {
      for (let index = 0; index < state.area.boxes.length; index += 1) {
        state.area.boxes[index].largura = state.boxLarguraEmMassa;
      }
    }

    function atualizarAlturaTodosBoxes() {
      for (let index = 0; index < state.area.boxes.length; index += 1) {
        state.area.boxes[index].altura = state.boxAlturaEmMassa;
      }
    }

    function preencheMedidasMapa() {
      const myImg = document.querySelector('#AreaImgMapa') as any;
      if (myImg !== null) {
        if (state.area.mapaAltura === 0) {
          state.area.mapaAltura = myImg.naturalHeight;
        }

        if (state.area.mapaLargura === 0) {
          state.area.mapaLargura = myImg.naturalWidth;
        }
      }
    }

    function novoValorPeriodo() {
      const valorPeriodo:IAreaValorPeriodo = {} as IAreaValorPeriodo;
      valorPeriodo.codigo = 0;
      valorPeriodo.codigoArea = state.area.codigo;
      valorPeriodo.periodoInicial = '01/01';
      valorPeriodo.periodoFinal = '01/01';
      valorPeriodo.descricao = '';
      valorPeriodo.tipoCobranca = ETipoCobrancaBox.PorDia;
      valorPeriodo.valorBox = 0;
      state.area.valoresPorPeriodo.push(valorPeriodo);
    }

    function removerValorPeriodo(index:number) {
      state.area.valoresPorPeriodo.splice(index, 1);
    }

    function estrategiaImagemMapa() {
      preencheMedidasMapa();
    }
    function limparDados() {
      state.boxEditarInformacoes = {} as IAreaBox;
      state.apresentarModalEditarInformacaoBox = false;
      state.area = {} as IArea;
      state.area.codigo = 0;
      state.area.status = true;
      state.area.tipoControle = 0;
      state.area.valoresPorPeriodo = [];
      state.area.boxesStatus = [];
      state.area.boxes = [];
      state.area.mapa = '';
      const statusBoxDisponivel:IAreaBoxStatus = {} as IAreaBoxStatus;
      statusBoxDisponivel.codigo = 0;
      statusBoxDisponivel.codigoArea = 0;
      statusBoxDisponivel.nome = 'Disponível';
      statusBoxDisponivel.cor = '#4de52e';
      statusBoxDisponivel.status = EBoxStatus.Disponivel;
      state.area.boxesStatus.push(statusBoxDisponivel);

      const statusBoxReservado:IAreaBoxStatus = {} as IAreaBoxStatus;
      statusBoxReservado.codigo = 0;
      statusBoxReservado.codigoArea = 0;
      statusBoxReservado.nome = 'Reservado';
      statusBoxReservado.cor = '#f42a2a';
      statusBoxReservado.status = EBoxStatus.Reservado;
      state.area.boxesStatus.push(statusBoxReservado);

      const statusBoxIndisponivel:IAreaBoxStatus = {} as IAreaBoxStatus;
      statusBoxIndisponivel.codigo = 0;
      statusBoxIndisponivel.codigoArea = 0;
      statusBoxIndisponivel.nome = 'Indisponível';
      statusBoxIndisponivel.cor = '#bdbdbd';
      statusBoxIndisponivel.status = EBoxStatus.Indisponivel;
      state.area.boxesStatus.push(statusBoxIndisponivel);
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoarea = obterCodigoRota();
      if (codigoarea > 0) {
        await obterArea(codigoarea);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Área.');
      if (state.area.codigo === 0) {
        appBase.resposta = await servicoArea.incluir(state.area);
      } else {
        appBase.resposta = await servicoArea.atualizar(state.area);
      }
      apresentarResposta();

      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        await obterArea(appBase.resposta.codigo);
        if (state.area.quantidadeBox > 0 && state.area.boxes.length === 0) {
          preencherBoxConformeQuantidade();
        }
      }
    }

    function dragElement(event:any) {
      if (state.indexBoxDrag >= 0) {
        if (event.offsetX > 0 && event.offsetY > 0) {
          state.area.boxes[state.indexBoxDrag].posicaoX = event.offsetX;
          state.area.boxes[state.indexBoxDrag].posicaoY = event.offsetY;
        }
      }
    }
    function stopDragging() {
      state.indexBoxDrag = -1;
      state.isDragging = false;
    }

    function selectParkingSpace(index:number) {
      state.indexBoxDrag = index;
      state.indexBoxEdicao = index;
      state.startX = state.area.boxes[index].posicaoX;
      state.startY = state.area.boxes[index].posicaoY;
    }

    function obterCorStatus(codigoBoxStatus:number):string {
      const status = state.area.boxesStatus.find((c) => c.codigo === codigoBoxStatus);
      if (status !== undefined) {
        return status.cor;
      }

      return '';
    }

    function removerBox(index:number) {
      state.area.boxes.splice(index, 1);
      state.indexBoxEdicao = -1;
      state.area.quantidadeBox -= 1;
    }

    function preparaEdicaoInformacaoBox() {
      state.boxEditarInformacoes = UtilitarioGeral.instanciaObjetoLocal(state.area.boxes[state.indexBoxEdicao]);
      state.apresentarModalEditarInformacaoBox = true;
    }

    function confirmarEdicaoInformacaoBox() {
      state.area.boxes[state.indexBoxEdicao] = UtilitarioGeral.instanciaObjetoLocal(state.boxEditarInformacoes);
      state.apresentarModalEditarInformacaoBox = false;
    }

    return {
      appBase,
      state,
      salvar,
      preencheMedidasMapa,
      preencherBoxConformeQuantidade,
      estrategiaImagemMapa,
      stopDragging,
      dragElement,
      atualizarLarguraTodosBoxes,
      atualizarAlturaTodosBoxes,
      selectParkingSpace,
      obterCorStatus,
      novoValorPeriodo,
      removerValorPeriodo,
      atualizarQuantidadeBox,
      removerBox,
      preparaEdicaoInformacaoBox,
      confirmarEdicaoInformacaoBox,
    };
  },
});
