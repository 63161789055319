
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SelecionarDiaMes',
  components: {
  },
  props: {
    valor: {
      type: String,
      default: '',
    },
    dia: {
      type: String,
      default: '',
    },
    mes: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'update:dia', 'update:mes', 'change'],
  setup(props, { emit }) {
    function obterDia() {
      let dia = '';
      if (props.valor.includes('/')) {
        dia = props.valor.substring(0, 2);
        emit('update:dia', dia);
      } else {
        dia = props.dia;
      }

      return dia;
    }
    const computedDia = computed({
      get: () => obterDia(),
      set: (valor: string) => {
        if (valor === undefined || valor === null) {
          emit('update:dia', '');
        } else {
          emit('update:dia', valor);
          // eslint-disable-next-line no-use-before-define
          emit('update:valor', `${valor}/${computedMes.value}`);
        }
      },
    });

    function obterMes() {
      let mes = '';
      if (props.valor.includes('/')) {
        mes = props.valor.substring(3, 5);
        emit('update:mes', mes);
      } else {
        mes = props.mes;
      }
      return mes;
    }

    const computedMes = computed({
      get: () => obterMes(),
      set: (valor: string) => {
        if (valor === undefined || valor === null) {
          emit('update:mes', '');
        } else {
          emit('update:mes', valor);
          emit('update:valor', `${computedDia.value}/${valor}`);
        }
      },
    });

    return {
      props,
      computedDia,
      computedMes,
      emit,
    };
  },
});
