
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IDTOArea } from '@/models/DTO/IDTOArea';
import ServicoArea from '@/servicos/ServicoArea';
import { IAreaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IAreaParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IOptionString } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'AreaListagem',
  components: {
    Icone,
    ComunicacaoApi,
  },
  setup() {
    const { appBase, apresentarComunicacaoApi, apresentarResposta } = useAppBase();
    const servicoArea = new ServicoArea();

    const state = reactive({
      areas: [] as IDTOArea[],
      parametrosConsulta: {} as IAreaParametrosConsulta,
      apresentarFiltros: false,
      ordenarPor: 'CodigoAreaDESC',
      opcoesOrdenacao: [] as IOptionString[],
    });
    let debounce = 0;

    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoAreaDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomeAreaASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomeAreaDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    async function buscarDados() {
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.areas = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }
      state.parametrosConsulta.qtdeRegistrosTotal = 100;
      const respostaConsulta = await servicoArea.obterAreas(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.areas = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarAreaPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }
    async function limparFiltros() {
      state.parametrosConsulta.status = undefined;
      await buscarDados();
    }
    onBeforeMount(async () => {
      await buscarDados();
    });

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    async function deletarArea(codigo: number) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoArea.deletar(codigo);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        const index = state.areas.findIndex((u) => u.codigo === codigo);
        if (index >= 0) {
          state.areas.splice(index, 1);
        }
      }
      apresentarResposta();
    }
    function confirmaExclusao(Area:IDTOArea) {
      Modal.confirm({
        title: 'Você confirma a exclusão da área:',
        content: `${Area.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await deletarArea(Area.codigo); },
      });
    }
    return {
      appBase,
      state,
      UtilitarioMascara,
      buscarDados,
      limparFiltros,
      buscarAreaPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      confirmaExclusao,
    };
  },
});
